import { EuroOutlined, MailOutlined, ProjectOutlined, UserOutlined } from "@ant-design/icons";
import i18n from "translations/i18n";
import urls from "urls";

const shopNavTree = [
  {
    key: "shop",
    title: i18n.t("shop"),
    breadcrumb: false,
    submenu: [
      {
        key: "projects",
        title: i18n.t("projects"),
        path: urls.projectList,
        icon: ProjectOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-requests",
        title: i18n.t("requests"),
        icon: EuroOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "requests-quote",
            title: i18n.t("quotes"),
            path: urls.quotationRequests,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "requests-configurator",
            title: i18n.t("configurator"),
            path: urls.configuratorRequests,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "requests-business-customer",
            title: i18n.t("business_quotes"),
            path: urls.businessQuotationRequests,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "forms",
        title: i18n.t("forms"),
        icon: MailOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "contact-forms",
            title: i18n.t("contact_forms"),
            path: urls.contactForms,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "voice-meetings",
            title: i18n.t("voice_meetings"),
            path: urls.voiceMeetings,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "vacancy-applications",
            title: i18n.t("vacancy_applications"),
            path: urls.vacancyApplications,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const adminNavTree = [
  {
    isAdmin: true,
    key: "admin",
    title: i18n.t("admin"),
    breadcrumb: false,
    submenu: [
      {
        key: "admin-users",
        path: urls.users,
        title: i18n.t("users"),
        icon: UserOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...shopNavTree, ...adminNavTree];

export default navigationConfig;
