const base = process.env.REACT_APP_BASEURL;
const dashboardUrl = "/cms";
const authUrl = "/auth";
const mailService = process.env.REACT_APP_API_URL + "/api/email";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  forgotPassword: authUrl + "/forgot-password",
  acceptInvite: authUrl + "/invite/",
  projects: dashboardUrl + "/projects",
  projectList: dashboardUrl + "/projects/project-list",
  addProject: dashboardUrl + "/projects/add-project",
  editProject: dashboardUrl + "/projects/edit-project/",
  quotationRequests: dashboardUrl + "/requests/quotation",
  businessQuotationRequests: dashboardUrl + "/requests/business-quotation",
  configuratorRequests: dashboardUrl + "/requests/configurator",
  customerList: dashboardUrl + "/orders/customers",
  contactForms: dashboardUrl + "/forms/contact-forms",
  voiceMeetings: dashboardUrl + "/forms/voice-meetings",
  vacancyApplications: dashboardUrl + "/forms/vacancy-applications",
  users: dashboardUrl + "/admin/users",
  reports: dashboardUrl + "/reports",
  sendInvite: mailService + "/invite/cms",
};
